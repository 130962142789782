.box-button {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  z-index: 0;
  background-color: var(--theme-color);
}

.box-button-active {
  position: relative;
  z-index: 0;
}

.box-button-active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  background: linear-gradient(90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
  background-size: 200% 200%;
  z-index: -1;
  border-radius: 15px;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  animation: gradient-border 3s ease infinite;
  pointer-events: none;
}

.box-button-active::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: inherit;
  z-index: -2;
  border-radius: 13px;
}

@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
