.sidebar-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 18px;
    padding: 10px;
    height: 10px;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    color: white;
}

.sidebar-item.selected {
    color: #040A2F;
    background: linear-gradient(-90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
    background-size: 200% 200%;
    animation: gradient-background 3s ease infinite;
    border: 2px solid transparent;
}

.sidebar-item:not(.selected) {
    border: 2px solid #1ECDF8;
    border-radius: 20px;
}

.sidebar-item:not(.selected)::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(-90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
    background-size: 200% 200%;
    z-index: -1;
    border-radius: 20px;
    animation: gradient-border 3s ease infinite;
    color: #040A2F !important
}

.sidebar-item:not(.selected):hover {
    background: transparent;
    color: #040A2F;
    border-color: transparent;
}

.sidebar-item:not(.selected):hover::before {
    filter: brightness(1.2);
    background-color: rgba(30, 205, 248, 0.4);
}

.sidebar-item.selected::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(-90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
    background-size: 200% 200%;
    z-index: -1;
    border-radius: 20px;
    animation: gradient-border 3s ease infinite;
}

@keyframes gradient-border {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient-background {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}