.gradient-button {
  position: relative;
  display: inline-block;
  font-size: 100%;
  font-weight: bold;
  color: white;
  text-transform: none;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  background: linear-gradient(-90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gradient-button::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(-90deg, #1ECDF8, #0064FB, #1ECDF8, #0064FB);
  background-size: 200% 200%;
  z-index: -1;
  border-radius: 10px;
  animation: gradient-border 3s ease infinite;
}

.gradient-button::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: transparent;
  border-radius: 8px;
  z-index: -1;
  transition: background-color 0.3s ease;
}

.gradient-button:hover {
  background-color: transparent;
}

.gradient-button:hover::after {
  background-color: #040A2F;
}

.gradient-button:hover::before {
  filter: brightness(1.2);
}

.gradient-button-red {
  position: relative;
  display: inline-block;
  font-size: 100%;
  font-weight: bold;
  color: white;
  text-transform: none;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  background: linear-gradient(-90deg, #990000, #ff0000, #990000, #ff0000);
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gradient-button-red::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(-90deg, #990000, #ff0000, #990000, #ff0000);
  background-size: 200% 200%;
  z-index: -1;
  border-radius: 10px;
  animation: gradient-border 3s ease infinite;
}

.gradient-button-red::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: transparent;
  border-radius: 8px;
  z-index: -1;
  transition: background-color 0.3s ease;
}

.gradient-button-red:hover {
  background-color: transparent;
}

.gradient-button-red:hover::after {
  background-color: #040A2F;
}

.gradient-button-red:hover::before {
  filter: brightness(1.2);
}

@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}